.component-wrapper {
    margin: 20px 40px;
    display: flex;
    flex-direction: column;
    animation: appear 0.3s forwards;
    @media screen and (max-width: 600px) {
        margin: 10px;
    }
}

.middle-title {
    font-size: 20px;
    font-weight: 500;
    margin: 20px 0 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 46px;
    text-transform: capitalize;

    &-title {
        height: 46px;
        display: flex;
        align-items: center;

        mat-icon {
            margin-left: 5px;
        }
    }
    &-actions {
        display: flex;
        align-items: center;
        gap: 10px;
    }
}

.secondary-title {
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 26px;
}
