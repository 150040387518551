:root {
    --frame-table-header-background-color: white;
    --frame-table-header-color: rgba(0, 0, 0, 0.54);
}

.filters-button {
    padding: 0;
    border-color: rgba(0,0,0,0.15);
}

table:not(.mat-calendar-table) {
    width: 100%;

    border-top: 1px solid #e3e3e3;
}

table:not(.mat-calendar-table), mat-table {
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    overflow: hidden;

    tr:last-child td, mat-row:last-child {
        border-bottom: none;
    }
}

lib-full-table mat-toolbar {
    border-bottom: none;
}

.mat-row.cdk-row.odd, .mat-header-row.cdk-header-row,
.gray-table-row {
    background-color: #f6f7f9;
}

.mat-sort-header-container {
    font-weight: 500;
    color: rgba(0,0,0,0.54);
}

lib-full-table {
    .mdc-data-table__cell, .mdc-data-table__header-cell {
        margin: 0 16px 0 16px;
        padding: 0;
        border: none;
    }
    .mat-mdc-header-row.mdc-data-table__header-row {
        background-color: var(--frame-table-header-background-color);
        .mat-sort-header-arrow, .mat-sort-header-content {
            color: var(--frame-table-header-color);
        }
    }
}

lib-full-table, .table-wrapper {

    // mat-table,
    mat-paginator,
    mat-toolbar {
        background-color: transparent;
    }

    .component-content {
        .filters {
            mat-form-field {
                margin-right: 0;
            }
            .filters-button {
                margin-left: 10px;
            }
        }
    }

    .mat-mdc-row:hover {
        background-color: #cecece;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
        margin-left: initial;
    }
}
