.custom-snackbar {
    .mdc-snackbar__surface {
        background-color: var(--primary-color) !important;
        color: white !important;
        button {
            background: white !important;
            color: var(--primary-color) !important;
        }
    }
}

.error-snackbar {
    .mdc-snackbar__surface {
        background-color: #AF0000 !important;
        color: white !important;
        button {
            background: white !important;
            color: #AF0000 !important;
        }
    }
}
