.appear-animation {
    animation: appear 0.3s forwards;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(100%);
    }
}