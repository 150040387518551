@use '@angular/material' as mat;
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$blue-palette: (
    50 : #e2edfd,
    100 : #b6d1fb,
    200 : #86b3f8,
    300 : #5594f5,
    400 : #307df2,
    500 : #0c66f0,
    600 : #0a5eee,
    700 : #0853ec,
    800 : #0649e9,
    900 : #0338e5,
    A100 : #ffffff,
    A200 : #d9e0ff,
    A400 : #a6b6ff,
    A700 : #8ca1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$orange-palette: (
    50 : #fdebe5,
    100 : #fbcebe,
    200 : #f8ad92,
    300 : #f58c66,
    400 : #f37346,
    500 : #f15a25,
    600 : #ef5221,
    700 : #ed481b,
    800 : #eb3f16,
    900 : #e72e0d,
    A100 : #ffffff,
    A200 : #ffe2df,
    A400 : #ffb5ac,
    A700 : #ff9f92,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat.m2-define-palette($orange-palette, 500);
$accent: mat.m2-define-palette($blue-palette, 500);

// The warn palette is optional (defaults to red).
// $warn: mat.m2-define-palette(mat.$red-palette, 500);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$theme: mat.m2-define-light-theme((
    color: (
      primary: $primary,
      accent: $accent,
    ),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

@import '@overflo1/frame-frontend/styles/form';
@import '@overflo1/frame-frontend/styles/page';
@import '@overflo1/frame-frontend/styles/snackbar';
@import '@overflo1/frame-frontend/styles/table';
@import '@overflo1/frame-frontend/styles/popup';
@import '@overflo1/frame-frontend/styles/animations';
@import '@overflo1/frame-frontend/styles/tab';
@import '@overflo1/frame-frontend/styles/mixed-material-overrides';


.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-input-element:disabled {
    color: rgba(0, 0, 0, 0.7);
}

.mat-row.cdk-row:nth-child(even), .mat-header-row.cdk-header-row {
    background-color: #f6f7f9;
}

// temporary override until dynamic-form is fixed
@-moz-document url-prefix() {
    .calendar-icon {
        display: none !important;
    }
}


*:not(mat-icon):not(span.material-icons) {
    font-family: Poppins !important;
}

:root {
    --primary-color: #f15a25;
    --accent-color: #0c66f0;
    --frame-table-header-background-color: #f15a25;
    --frame-table-header-color: white;
    // --fulltableHeaderBackgroundColor: #f15a25;
    // --fulltableHeaderTextColor: white;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Poppins';
    background-color: var(--primary-color);
}