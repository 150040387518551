
.mat-mdc-tab-labels {
    padding-bottom: 5px;
    display: flex;
    justify-content: center;

    .mdc-tab {
        max-width: 150px;
        box-shadow: 0 0px 4px 0 rgba(0,0,0,0.1);
        border: 1px solid #d7d7d7;
        background-color: white;
        overflow: hidden;

        &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &:not(:last-child) {
            border-right: none;
        }

        .mdc-tab__text-label {
            letter-spacing: initial;
        }
    }
}