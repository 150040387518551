*:not(mat-icon):not(span.material-icons) {
    font-family: Maven Pro, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.mat-form-field-outline, .mat-mdc-text-field-wrapper {
    background-color: #f6f7f9;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
    border-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
}

.mdc-button__label {
    letter-spacing: normal;
}
// .mat-toolbar {
//     --mat-toolbar-container-background-color: red;
// }