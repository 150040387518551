.popup-close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;

    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    mat-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
        font-weight: 600;
    }
}